import styled from "styled-components"

import searchIcon from "../../images/ic-search.svg"
import closeIcon from "../../images/ic-close.svg"

import Select from "react-select"

export const OpenControl = styled.div`
  position: absolute;
  right: 0;
  display: none;
  background: url(${searchIcon}) 10px 16px / auto no-repeat,
    linear-gradient(to bottom, #efefef, #efefef);
  height: 51px;
  width: 36px;
  top: 10px;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  box-shadow: -2px 2px 10px rgba(0, 0, 0, 0.5);
  z-index: 1001;
  transition: 0.5s all;

  @media (max-width: 768px) {
    display: block;
    ${({ active }) =>
    active &&
    `
        right: 310px;
        box-shadow: -10px 3px 10px rgba(0, 0, 0, 0.2), 0px 2px 4px rgba(0, 0, 0, 0.2);
        background: url(${closeIcon}) 8px 14px / auto no-repeat,
          linear-gradient(to bottom, #efefef, #efefef);
      `}
  }
`

export const WrapperSearch = styled.div`
  position: absolute;
  right: 10px;
  top: 10px;
  z-index: 1000;
  background-color: #efefef;
  padding: 10px;
  border-radius: 4px;
  box-shadow: -2px 2px 10px rgba(0, 0, 0, 0.5);
  max-width: 300px;
  width: 100%;
  transition: 0.5s all;

  @media (max-width: 768px) {
    right: -300px;
    ${({ active }) =>
    active &&
    "right: 10px; border-top-left-radius: 0; border-bottom-left-radius: 0;"}
  }
`

export const Form = styled.form`
  display: flex;
`

export const SearchInput = styled.input`
  background: url(${searchIcon}) 8px 8px / auto no-repeat,
    linear-gradient(to bottom, #d8d8d8, #d8d8d8);
  color: #727272;
  border: none;
  padding: 8px 8px 8px 32px;
  border-radius: 4px;
  font-size: 12px;
  width: 100%;



  &::placeholder {
    color: #727272;
  }
`

export const SearchSelect = styled(Select)`
  .react-select__control {
    background: #d8d8d8;
    font-size: 12px;
    border: none;
    min-height: 31px;
    min-width: 92px;
  }

  .react-select__indicator-separator {
    display: none;
  }

  .react-select__indicator {
    padding: 0 8px 0 0;

    svg {
      color: #727272;
    }
  }

  .react-select__placeholder {
    color: #727272;
    opacity: 0.5;
  }

  .react-select__single-value {
    color: #727272;
  }

  .react-select__menu {
    width: auto;
    right: 0;
    font-size: 12px;
    text-align: center;
    background: #dadddf;
    color: #727272;
    box-shadow: -2px 2px 10px rgba(0, 0, 0, 0.5);
  }

  .react-select__option--is-focused,
  .react-select__option--is-selected {
    background: #727272;
    color: white;
  }
`

export const ChevronHolder = styled.div``

export const VenuesList = styled.div`
  max-height: 400px;
  overflow-y: scroll;
  max-width: 100%;
  padding-top: 4px;
  margin-top: 8px;
`

export const Venue = styled.div`
  display: flex;
  color: #525252;
  align-items: center;
  padding: 8px 0;
  border-bottom: solid 1px #d2d2d2;
  cursor: pointer;

  span {
    font-size: 18px;
    margin-right: 8px;
  }

  h4 {
    font-size: 11px;
    margin: 0 0 2px 0;
  }

  p {
    font-size: 10px;
    margin: 0;
    text-transform: capitalize;
  }
`

export const Reset = styled.button`
  width: 100%;
  margin-top: 8px;
  background: #f05d46;
  border: none;
  padding: 6px;
  font-size: 12px;
  color: #f1f1f1;
  border-radius: 4px;
  opacity: 0.8;
  cursor: pointer;
`
